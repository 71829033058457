import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { throwError } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { catchError, retry } from 'rxjs/operators';

import { ToastLuncherService } from '../services/toast-luncher.service';
import { UserFacade } from '../store';
import { expiringIn } from '../lib/functions';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  static _toast: ToastLuncherService;

  constructor(
    private _userFacade: UserFacade,
    public _toastLuncherService: ToastLuncherService,
    public jwtHelper: JwtHelperService,
  ) {
    TokenInterceptor._toast = this._toastLuncherService;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // token needs refresh
    if (
      this._userFacade.isAuthenticated() &&
      expiringIn(6, this.jwtHelper.getTokenExpirationDate(UserFacade.getToken()).getTime())
    ) {
      this._userFacade.refresh();
    }

    // add token header
    if (this._userFacade.isAuthenticated()) {
      request = request.clone({ setHeaders: { Authorization: `Bearer ${UserFacade.getToken()}` } });
    }
    return next.handle(request).pipe(retry(1), catchError(this.handelError));
  }

  private handelError(error: HttpErrorResponse) {
    return throwError(error);
  }
}
