import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule, ErrorHandler, Injector, Injectable, InjectionToken } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UrlSerializer } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';
import Bugsnag from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { FacebookModule } from 'ngx-facebook';
import { LightboxModule } from 'ngx-lightbox';
import { environment } from 'src/environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TokenInterceptor } from './core/auth/token.interceptor';
import { OfflineComponent } from './components/offline/offline.component';

import { MunicipalityService } from './services/municipality.service';
import { MunicipalityUrlSerializer } from './services/MunicipalityUrlSerializer';
import { NotificationService } from './services/notification.service';
import { ToastLuncherService } from './services/toast-luncher.service';
import { StateModule } from './core/store/src/state.module';
import { UserService } from './core/services/user.service';
import { DEFAULT_TIMEOUT } from './core/auth/timeout.interceptor';
import { initializeApp } from 'firebase/app';

initializeApp(environment.firebase);

Bugsnag.start({ apiKey: environment.bugsnagKey });

export function errorHandlerFactory() {
  return new BugsnagErrorHandler();
}

export const ERROR_HANDLERS = new InjectionToken<ErrorHandler[]>('ERROR_HANDLERS');

@Injectable({ providedIn: 'root' })
export class CombineErrorHandlers implements ErrorHandler {
  constructor(private injector: Injector) {}
  handleError(error: any): any {
    const handlers = this.injector.get<ErrorHandler[]>(ERROR_HANDLERS, []);
    handlers.forEach((errorHandler: ErrorHandler) => {
      errorHandler.handleError(error);
    });
  }
}

@NgModule({
  declarations: [AppComponent, OfflineComponent],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    LightboxModule,
    FacebookModule.forRoot(),
    StateModule,

    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately',
    }),
  ],
  providers: [
    { provide: DEFAULT_TIMEOUT, useValue: 25000 },
    {
      provide: ERROR_HANDLERS,
      useExisting: ToastLuncherService,
      multi: true,
    },
    {
      provide: ERROR_HANDLERS,
      useFactory: errorHandlerFactory,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useExisting: CombineErrorHandlers,
    },
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: UrlSerializer, useClass: MunicipalityUrlSerializer },
    JwtHelperService,
    MunicipalityService,
    UserService,
    NotificationService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
platformBrowserDynamic().bootstrapModule(AppModule);
