import Backend from 'src/app/core/backend';
import { MunicipalityGlobal } from '../models/class/municipality';
import { Notification } from '../models/class/notification';
import { CommentTypes, NotificationType, PollStatusTypes, UpdateTypes } from '../models/types';
import { AccessRequestPipe } from '../pipes/access-request.pipe';
import { ArabicTimeAgoPipe } from '../pipes/arabic-time-ago.pipe';
import { ComplaintStatusPipe } from '../pipes/complaint-status.pipe';
import { DossierStatusPipe } from '../pipes/dossier-status.pipe';
import { StatusColorPipe } from '../pipes/status-color.pipe';
import { StorageItem, getItem, setItem } from './local-storage.utils';
import { DeviceDataCollected } from '../models/class/user';
import { notificationRoutes } from './const';

/**
 *
 * @param municipalities
 * @param key wanted to be extracted from an array of objects.
 * @returns Set of values plucked from array
 */
export const getStates = (municipalities: Array<MunicipalityGlobal>, key: string = 'city'): Set<any> => {
  return new Set(municipalities.map(value => value[key]));
};

// group them by city or key in a Map
export const mapMunicipalities = (
  municipalities: Array<MunicipalityGlobal>,
  key: string = 'city',
): Map<string, any[]> => {
  const mappedMunicipalities = new Map();
  const states = getStates(municipalities);
  states.forEach(state => {
    mappedMunicipalities.set(
      state,
      municipalities.filter(value => value[key] === state),
    );
  });
  return mappedMunicipalities;
};

export const formatMunicipalityName = (mun: string): string => (mun ? mun.toLowerCase().split(' ').join('-') : '');

/**
 *
 * @param input string to check if valid
 * @param length the required length of that string
 * @returns boolean
 */
const isValidStringOfNumbers = (input: string, length: number) => {
  const output = input.toString();
  const validStringOfNumbers = new RegExp('^[0-9]+$');
  return validStringOfNumbers.test(output) && output.length === length;
};

export const isValidPhoneNumber = (phone_number: string | number) => {
  phone_number = phone_number.toString().replace(/\s/g, ''); // remove whitespaces;

  if (phone_number.includes('+216')) phone_number = phone_number.replace('+216', '');

  return isValidStringOfNumbers(phone_number, 8);
};
/**
 *
 * @param id a unique identifier for a permit request ...
 * @returns boolean
 */
export const isValidIdentifier = (input: string, minLength: number = 3) => {
  const output = input;
  return typeof input === 'string' && output?.length >= minLength;
};

/**
 *
 * @param id a tunisian citizen id
 * @returns boolean
 */
export const isValidCitizenId = (num: string | number) => {
  return isValidStringOfNumbers(num.toString(), 3);
};

const timePipe = new ArabicTimeAgoPipe();

export const timeAgo = (date: string | number): string => {
  return timePipe.transform(date.toString());
};

export const openLink = (link: string) => {
  if (typeof link === 'string') window.open(Backend.baseUrl + link, '_blank');
};

export const ItemIsOpenForProcessing = (item: any) =>
  item.status === UpdateTypes.RECEIVED || item.status === UpdateTypes.PROCESSING;

export const getPollStatus = (start: Date | string, end: Date | string) => {
  const today = new Date();
  if (today < new Date(start)) return PollStatusTypes.ENDED;
  if (today > new Date(start) && today < new Date(end)) return PollStatusTypes.IN_PROGRESS;
  return PollStatusTypes.ENDED;
};

export const commentTypeToArabic = (value: CommentTypes | string): string => {
  switch (value) {
    case CommentTypes.REMARK:
      return 'ملاحظة';
    case CommentTypes.QUESTION:
      return 'سؤال';
    // default to SUGGESTION
    default:
      return 'مقترح';
  }
};

const statusColorPipe = new StatusColorPipe();
export const statusColor = (input: string): string => {
  return statusColorPipe.transform(input);
};

export const toArabicDate = (date: string) => {
  return new Date(Date.parse(date)).toLocaleDateString('ar-tn', {
    weekday: 'long',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
};

/**
 *
 * @param arr an array of objects
 * @param key to pluck out distinct values
 * @returns
 */
export const getDistinctKeysFrom = (arr: any[], key: string) =>
  [
    ...new Set(
      arr.map(i => {
        return i[key];
      }),
    ),
  ].map((i, idx) => {
    return { id: idx, item: i };
  });

export const notificationsNormalized = (notifications: Array<Notification>): Array<Notification> => {
  if (notifications)
    notifications.forEach(item => {
      switch (item.subject_type) {
        case NotificationType.DOSSIER:
          item.title = 'هناك تحيين يتعلق بأحد مطالبك';
          item.redirect_to = formatMunicipalityName(item.municipality_name_fr) + '/dossiers/' + item.subject_id;
          item.body = new DossierStatusPipe().transform(item.body);
          break;
        case NotificationType.COMPLAINT:
          item.title = 'هناك تحيين يتعلق بأحد تشكياتك';
          item.redirect_to = formatMunicipalityName(item.municipality_name_fr) + '/plaintes/' + item.subject_id;
          item.body = new ComplaintStatusPipe().transform(item.body);
          break;
        case NotificationType.SUBJECT_ACCESS_REQUEST:
          item.title = 'هناك تحيين يتعلق بأحد مطالبك';
          item.redirect_to = formatMunicipalityName(item.municipality_name_fr) + '/accesinfo/' + item.subject_id;
          item.body = new AccessRequestPipe().transform(item.body);
          break;
        case NotificationType.FORUM:
          item.title = 'فضاء المقترحات';
          item.redirect_to = formatMunicipalityName(item.municipality_name_fr) + '/forum/' + item.subject_id;
          item.body = 'أحد المواضيع التي تتابعها تحمل تعليقاً جديداً';
          break;
        case NotificationType.EVENT:
          item.title = 'رزنامة المواعيد و الأنشطة';
          item.redirect_to = formatMunicipalityName(item.municipality_name_fr) + '/calendrier/' + item.subject_id;
          item.body = 'أضافت بلديتك حدثًا جديدًا';
      }
      // TODO add default case
    });
  return notifications;
};

export const getCommentTypeIcon = (type: CommentTypes | string, prefix = '') => {
  // defaults to remark
  switch (type) {
    case CommentTypes.QUESTION:
      return './assets/images/ico/' + prefix + 'question.svg';
    case CommentTypes.SUGGESTION:
      return './assets/images/ico/' + prefix + 'suggestion.png';
    default:
      return './assets/images/ico/' + prefix + 'remark.svg';
  }
};

export const monthsSince = (lastUpdate: string): boolean => {
  const today = new Date();
  const updateDate = new Date(lastUpdate.replace('-', ' '));
  let months;
  months = (today.getFullYear() - updateDate.getFullYear()) * 12;
  months -= updateDate.getMonth();
  months += updateDate.getMonth();
  return months <= 0 ? 0 : months;
};

/**
 *
 * @returns error = true, if one the files uploaded doesn't correspond the formats/contrains we use.
 */
export const areValidImages = (files: FileList): { error: boolean; errorMessage?: string } => {
  const supportedTypes: string[] = ['png', 'jpg', 'jpeg', 'gif'];

  for (let idx = 0; idx < files.length; idx++) {
    const file = files[idx];
    if (!file.type || !supportedTypes.includes(file.type.replace('image/', '')))
      return { error: true, errorMessage: supportedTypes.join(' - ') + ' الرجاء ادخال صورة صحيحة' };

    if (!file.name || file.name.length > 100)
      return { error: true, errorMessage: 'اسم الملف طويل جدًا ، يرجى تغييره والمحاولة مرة أخرى' };
  }

  return { error: false };
};

export const normalizedPDF = (result: string | ArrayBuffer): string => {
  return result?.toString().split(',').pop();
};

/**
 * check refresh token is expiring in {hours}
 */
export const expiringIn = (hours, tokenExpirationDate): boolean =>
  tokenExpirationDate - new Date().getTime() < hours * 60 * 60 * 1000;

function generateDeviceID(): string {
  let machineId: string | null = getItem(StorageItem.unique_device_id);
  if (!machineId) {
    machineId = crypto.randomUUID();
    setItem(StorageItem.unique_device_id, machineId);
  }
  return machineId;
}

function getDeviceModel(userAgent: string): string {
  const match = userAgent.match(/(iPhone|iPad|iPod|Android|Windows Phone|Windows NT)[\s/]?([0-9A-Za-z]+)/i);
  return match ? match[0] : 'Unknown';
}

export function getDeviceData(): DeviceDataCollected {
  const deviceUniqueID = generateDeviceID(); // function that generates a unique device ID
  const userAgent = navigator.userAgent;
  const os = 'OTHER';
  const osVersion = 'Unknown';
  const lastVersion = 'Unknown';
  const model = getDeviceModel(userAgent);

  return {
    device_unique_id: deviceUniqueID,
    os: os,
    os_version: osVersion,
    last_version: lastVersion,
    model: model,
  };
}

export const navigateNotification = (model: string): string => {
  const route = notificationRoutes.find(route => route.model === model);
  return route !== undefined && typeof route?.destination === 'string' ? route.destination : '/';
};
