import { Component, OnInit, HostListener, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxImageCompressService } from 'ngx-image-compress';
import 'rxjs/add/operator/filter';

import { defaultAnimation, getItem, StorageItem } from 'src/app/core';
import Backend from 'src/app/core/backend';
import { Committee } from 'src/app/core/models/class/committee';
import { MainFeature } from 'src/app/core/models/class/GenericListFeature';
import { GenericCreateFeature } from 'src/app/core/models/class/GenericSingleView';
import { Municipality } from 'src/app/core/models/class/municipality';
import { Topic } from 'src/app/core/models/class/topic';
import { CommentTypes } from 'src/app/core/models/types';
import { MunisFacade, UiFacade, UserFacade } from 'src/app/core/store';
import { ToastLuncherService } from 'src/app/services/toast-luncher.service';
import { Comment, CommentReq } from '../../../core/models/class/comment';
import { CommitteeService } from '../../../services/committee.service';
import { ForumService } from '../../../services/forum.service';
import { TopicsService } from '../../../services/topics.service';

@Component({
  selector: 'app-forum-new',
  templateUrl: './forum-new.component.html',
  styleUrls: ['./forum-new.component.css'],
  ...defaultAnimation,
})
export class ForumNewComponent extends GenericCreateFeature<Comment> implements OnInit {
  committees: Committee[] = new Array<Committee>();
  topics: Topic[] = new Array<Topic>();
  item = new Comment();
  municipalityName;

  commentTypes = [
    { name: 'ســؤال', class: CommentTypes.QUESTION },
    { name: 'ملاحـظـة', class: CommentTypes.REMARK },
    { name: 'مقـترح', class: CommentTypes.SUGGESTION },
  ];
  screenWidth: number;
  loading = false;
  display = false;
  inCreationPanel = false;

  constructor(
    public committeeService: CommitteeService,
    public topicsService: TopicsService,
    public _userFacade: UserFacade,
    public _munisFacade: MunisFacade,
    public _uiFacade: UiFacade,
    public imageCompress: NgxImageCompressService,
    public _toastLauncher: ToastLuncherService,
    public forumService: ForumService,
    public route: ActivatedRoute,
    public router: Router,
  ) {
    super('forum', forumService, _userFacade, _toastLauncher, imageCompress, router);
    this._munisFacade.selectedMuni$.subscribe(muni => {
      if (muni) {
        this.onMunicipalityChange(muni);
      }
    });
  }

  ngOnInit() {
    this.screenWidth = window.innerWidth;
  }

  onMunicipalityChange(m: Municipality): void {
    this.inCreationPanel = this.router.url.split('/')[2].includes('nouveau-post');
    this.item.municipality_id = m.id;
    this.municipalityName = m.name;
    this.item.committee_id = null;
    this.getUser();
    this.getCommittees();
    this.getTopics();
  }

  setForumNew() {
    this.route.queryParams.subscribe(params => {
      // validate existence + values integrity
      if (params.committee || params.topic || params.type) {
        //@ts-ignore
        const types = [...this.commentTypes].map(type => type.class);
        const c = [...this.committees].map(x => x.id).indexOf(parseInt(params.committee));
        const t = [...this.topics].map(x => x.id).indexOf(parseInt(params.topic));

        if (c > -1)
          if (this.committees[c]) this.item.committee_id = this.committees[c].id;
          else throw new Error('حصل خطأ في إختيار اللجان');
        if (t > -1)
          if (this.topics[t])
            //@ts-ignore
            this.item.topic = this.topics[t].id;
          else throw new Error('حصل خطأ في إختيار المواضيع');

        if (params.type && types.includes(params.type.toUpperCase())) this.item.type = params.type.toUpperCase();
        else this.item.type = types[0];
        this.display = true;
      }
      this._uiFacade.setFeatureLoaded();
    });
  }

  copyForm() {
    const params = '?type=' + this.item.type + '&topic=' + this.item.topic + '&committee=' + this.item.committee_id;

    navigator.clipboard.writeText(
      this.frontUrl + getItem(StorageItem.municipality_route_name) + '/nouveau-post' + '/' + params,
    );
    this._toastLuncher.success({ text: 'تم نسخ الرابط بنجاح', showConfirmButton: false });
  }

  onPress() {
    this.display = true;
  }

  onImageLoad($event, compressedImage, ...args) {
    this.item.image = compressedImage;
  }

  removeImage() {
    this.item.image = null;
  }

  getCommittees() {
    this.committeeService.get().subscribe(data => {
      this.committees = data;
      this.item.committee_id = data[0]?.id;
    });
  }

  getTopics() {
    this.topicsService.get().subscribe(data => {
      this.topics = data.filter(t => t.state === 'ACTIVATED');
      //@ts-ignore
      this.item.topic = this.topics[0]?.id;
      this.setForumNew();
    });
  }

  getUser() {
    this._userFacade.loggedInUser$.subscribe(data => {
      this.item.created_by_id = data.id;
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.screenWidth = window.innerWidth;
  }

  getIcon(type: string, prefix = '') {
    // defaults to remark
    switch (type) {
      case CommentTypes.QUESTION:
        return '../../../assets/images/ico/' + prefix + 'question.svg';
      case CommentTypes.SUGGESTION:
        return '../../../assets/images/ico/' + prefix + 'suggestion.png';
      default:
        return '../../../assets/images/ico/' + prefix + 'remark.svg';
    }
  }

  getArabicText(str: string) {
    switch (str) {
      case CommentTypes.REMARK:
        return 'ملاحـظـة';
      case CommentTypes.QUESTION:
        return 'ســؤال';
      case CommentTypes.SUGGESTION:
        return 'مقـترح';
      default:
        return 'المشاركة';
    }
  }

  submit() {
    if (!this.isAuthenticated) return this.openLoginModal();
    this.loading = true;
    this.forumService.post(this.item).subscribe(
      data => {
        this._toastLuncher.success({
          showConfirmButton: false,
          text: 'تم إضافة المقترح بنجاح',
        });
        // navigate to single comment view + remove queryParams
        this.router.navigateByUrl(`/${getItem(StorageItem.municipality_route_name)}/forum/${data.id}`);

        this.loading = false;
      },
      err => {
        this.loading = false;
        throw new Error('لا يمكن الاتصال بالمنصة، الرجاء المحاولة لاحقاً');
      },
    );
  }
}
