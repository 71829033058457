import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { Agency, ReservationSignature } from '../core/models/class/agency';
import { Reservation } from '../core/models/class/agency';
import { BaseService } from '../core/services/base.service';

import { GenericReadActiveService } from '../core/services/generic.service';
@Injectable({
  providedIn: 'root',
})
export class AgenciesService extends GenericReadActiveService<Agency> {
  constructor(http: HttpClient) {
    super(http);
    this.prefix = '/agencies';
    this.suffix = '/';
  }

  public getReservation(agencyId:string): Observable<Reservation[]> {
    return (
      this.http.get(`${this.getUrl()}/${agencyId}/reservations`, {
        headers: BaseService.getHeaders(),
      }) as Observable<Reservation[]>
    ).pipe(first());
  }

  public getPhysicalToDigital(signature: string): Observable<Reservation> {
    const payload: ReservationSignature = {
      signature,
    };
    return (
      this.http.post(this.getUrl() + this.suffix + 'convert-physical-ticket', payload, {
        headers: BaseService.getHeaders(),
      }) as Observable<Reservation>
    ).pipe(first());
  }
}
