import { Component, OnInit } from '@angular/core';
import { Municipality } from 'src/app/core/models/class/municipality';
import { ToastLuncherService } from 'src/app/services/toast-luncher.service';
import { MunisFacade, UiFacade, UserFacade } from 'src/app/core/store';
import { defaultAnimation, setItem, StorageItem } from 'src/app/core';
import { MainFeature } from 'src/app/core/models/class/GenericListFeature';
import { Service } from 'src/app/core/models/class/service';
import { AgenciesService } from 'src/app/services/agencies.service';
import { ServicesService } from 'src/app/services/services.service';
import { Agency } from 'src/app/core/models/class/agency';
import { Reservation } from 'src/app/core/models/class/agency';
import { ReservationService } from 'src/app/services/reservations.service';

declare let $: any;
enum PanelType {
  SERVICES = 'SERVICES',
  PICK_AGENCY = 'PICK_AGENCY',
  SERVICES_IN_SINGLE_AGENCY = 'SERVICES_IN_SINGLE_AGENCY',
  RESERVATION = 'RESERVATION',
  AGENCY_IS_CLOSED='AGENCY_IS_CLOSED',
  AGENCY_IS_NOT_REACHABLE='AGENCY_IS_NOT_REACHABLE',
  AGENCY_NO_ETICKET='AGENCY_NO_ETICKET'
}
@Component({
  selector: 'app-ticket-list',
  templateUrl: './ticket-list.component.html',
  styleUrls: ['./ticket-list.component.css'],
  ...defaultAnimation,
})
export class TicketListV2Component extends MainFeature implements OnInit {
  agencies: Agency[] = [];
  services: Service[] = [];
  reservations: Reservation[] = [];

  onHoldServiceName: string = null;
  selectedAgency: Agency = null;
  selectedReservation: Reservation = null;
  pdfHasBeenLoaded: boolean = false;

  panelToShow: PanelType = PanelType.SERVICES;
  themeColors = ['#58C0E6', '#EAAD7C', '#8079C8'];
  isAuthenticated = this._userFacade.isAuthenticated();
  panels = PanelType;

  constructor(
    private _munisFacade: MunisFacade,
    public _uiFacade: UiFacade,
    public _userFacade: UserFacade,
    public _servicesService: ServicesService,
    public _reservationsService: ReservationService,
    public _agenciesService: AgenciesService,
    public _toastLuncher: ToastLuncherService,
  ) {
    super(_userFacade, _toastLuncher);
  }

  ngOnInit(): void {
    this._munisFacade.selectedMuni$.subscribe((municipality: Municipality) => {
      if (municipality) {
        this.getAgencies();
        if (this.isAuthenticated) {
          
          if(this.selectedAgency)
          this.getReservations()
        else this.changePanel(PanelType.PICK_AGENCY)
        }
      }
    });
  }

  getAgencies() {
    this._agenciesService.get().subscribe(agencies => {

      this.agencies = agencies.filter(a => a.is_active && a.has_eticket);
      agencies.forEach(agency => this.getServices(agency.id));
      

      if(agencies.length===0)
     { 
      this.changePanel(PanelType.AGENCY_NO_ETICKET)
      
    }
      else if(agencies.length==1)
     { 
      this.selectedAgency=this.agencies[0]
      this.changePanel(PanelType.SERVICES)
    }
      else this.changePanel(PanelType.PICK_AGENCY)

      this._uiFacade.setFeatureLoaded();
     
    });
  }

  getServices(agency_id: string) {
    setItem(StorageItem.agency_id, agency_id);
    this._servicesService.get().subscribe(services => {
      this.services = services.filter(s => s.is_active)
    });
  }

  getReservations() {
    this._agenciesService.getReservation(this.selectedAgency.id).subscribe(reservations => {
      this.reservations = reservations.filter(r => r.is_still_valid === true);
    });
  }

  selectAgency(agency: Agency) {
    this.selectedAgency = agency;
    this.getReservations()
    setItem(StorageItem.agency_id, agency.id);
    if(agency.has_eticket)
    {
      if(!agency.is_open)
      {
        this.changePanel(PanelType.AGENCY_IS_CLOSED)
        return;
      } 
      if(!agency.local_reachable)
    {  this.changePanel(PanelType.AGENCY_IS_NOT_REACHABLE)

      return;}
      else {
        this.changePanel(PanelType.SERVICES);
        this.getServices(agency.id)
      }
    }
    else {
      this.changePanel(PanelType.AGENCY_NO_ETICKET)
      return;
    }
 
  }

  cancel(reservation: Reservation) {
    this._reservationsService.cancel(reservation).subscribe(
      data => {
        this.reservations = this.reservations.filter(r => r.id != reservation.id);
        this._toastLuncher.success({
          showConfirmButton: false,
          text: 'تم إلغاء حجزك بنجاح',
        });
        $('#reservationModal').modal('hide');
      },
      error => {
        throw new Error('لا يمكن إلغاء الحجز حاليًا الرجاء المحاولة لاحقاً');
      },
    );
  }

  download(reservation: Reservation) {
    this._reservationsService.download(reservation.id).subscribe(
      (data: Blob) => {
        const file = new Blob([data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        // open pdf in new page and ask to download
        window.open(fileURL);
      },
      error => {
        throw new Error('لم نتمكن من إنشاء التذكرة');
      },
    );
  }

  selectReservation(serviceName: string, agencyName: string) {
    const reservation = this.reservations.find(s => s.service_name === serviceName && s.agency_name === agencyName);
    if (reservation) {
      this.openReservationModel(reservation);
    }
  }

  openReservationModel(reservation: Reservation) {
    this.selectedReservation = reservation;
    $('#reservationModal').modal('show');
  }

  openDigitizeModel() {
    $('#digitizeModal').modal('show');
  }

  beenBooked(serviceName: string) {
    return this.reservations.find(r => r.service_name === serviceName) !== undefined;
  }

  reserveTicket(serviceName: string, showMultiAvailabilityPanel = true) {
    if (showMultiAvailabilityPanel && this.availableOnMultiAgency(serviceName)) {
      this.onHoldServiceName = serviceName;
      return;
    }

    const service = this.services.find(s => s.name === serviceName);
    if (!(service === undefined)) {
      this.isLoading = true;
      this._servicesService.bookById(service.id).subscribe(
        res => {
          this.isLoading = false;
          this.addReservation(res);
        },
        err => {
          this.isLoading = false;
          if (err.status === 400) {
            this.changePanel(PanelType.RESERVATION);
            throw new Error('لا يمكن حجز نفس الخدمة مرتين');
          }
          this.changePanel(PanelType.SERVICES);
          throw new Error('لم نتمكن من الاتصال البلدية ، يرجى المحاولة مرة أخرى لاحقًا');
        },
      );
    }
  }

  addReservation(newReservation: Reservation) {
    this.reservations = [...this.reservations, newReservation];
    this.changePanel(PanelType.RESERVATION);
  }

  getAgenciesProviding(serviceName: string): Agency[] {
    const agenciesIds = this.services.filter(s => s.name === serviceName).map(s => s.agency);
    return this.agencies.filter(a => agenciesIds.includes(a.id));
  }

  availableOnMultiAgency(serviceName: string): boolean {
    const count = this.services.filter(s => s.name === serviceName).length;
    return count !== 1;
  }

  changePanel(input: PanelType) {
    this.panelToShow = input;
  }

  pickColor(idx, arr = this.themeColors) {
    while (idx > arr.length - 1) {
      idx = idx - arr.length - 1;
    }
    return arr[idx];
  }
}
